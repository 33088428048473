import { store } from '../app/store';
import { getCallHistoryInitState } from '../slices/callHistory';
import { getScheduledCallsInitState } from '../slices/scheduledCalls';
import TwilioVoiceService from './TwilioVoiceService';

const LogTitle = 'SaleforceService';
// ref : https://resources.docs.salesforce.com/246/latest/en-us/sfdc/pdf/api_cti.pdf

export default class SaleforceService {
  static callingObjectNavigation(phoneNumber: string, contactId: string, isOutbound: boolean) {

    console.log(LogTitle, `objectSFNavigation phoneNumber ${phoneNumber} contactId: ${contactId} isOutbound: ${isOutbound}`);
    // @ts-ignore
    sforce?.opencti?.searchAndScreenPop({
      searchParams: phoneNumber,
      queryParams: '',
      defaultFieldValues: { rocketphone__ReferenceId__c: contactId, Phone: phoneNumber },
      // @ts-ignore
      callType: isOutbound ? sforce?.opencti?.CALL_TYPE.OUTBOUND : sforce?.opencti?.CALL_TYPE.INBOUND,
      deferred: false,
      callback: (response: any) => {
        if (response.success) {
          console.log("API method call executed successfully! searchAndScreenPop returnValue:", response.returnValue);
        } else {
          console.error("Something went wrong!searchAndScreenPop Errors:", response.errors);
        }
      },
    });
  }

  static navigateToObject(objectId: string) {
    // @ts-ignore
    sforce.opencti.screenPop({
      // @ts-ignore
      type: sforce.opencti.SCREENPOP_TYPE.SOBJECT, //Review the arguments section.
      params: { recordId: objectId } //Depends on the SCREENPOP_TYPE. Review the arguments section.
    });
  }

  static publishObject(message: object, channel: string) {
    console.log('published message:', {message, channel});
    // @ts-ignore
    sforce.opencti.publish({
      channelName: channel,
      message: message
    }); 
  }

  /*
      objectName : Opportunity, Case, Task
      referenceId: pass rp ref id 
  */
  static openObjectCreationModal(objectName: string, defaultFieldValues: any) {
    // @ts-ignore
    sforce.opencti.screenPop({
      // @ts-ignore
      type: sforce.opencti.SCREENPOP_TYPE.NEW_RECORD_MODAL,
      params: { entityName: objectName, defaultFieldValues }
    });
  }

  // SForce Library releted setup task
  static setSoftPhoneVisibleIfNot() {
    // @ts-ignore
    sforce?.opencti?.isSoftphonePanelVisible({
      callback: (responsee: any) => {
        if (responsee.success) {
          console.log("API method call executed successfully! isSoftphonePanelVisible returnValue:", responsee);
          if (!responsee.returnValue.visible) {
            // @ts-ignore
            sforce?.opencti?.setSoftphonePanelVisibility({
              visible: true,
              callback: (response: any) => {
                if (response.success) {
                  console.log(
                    "API method call executed successfully! setSoftphonePanelVisibility returnValue:",
                    response.returnValue
                  );
                } else {
                  console.error("Something went wrong!setSoftphonePanelVisibility Errors:", response.errors);
                }
              },
            });
          }
        } else {
          console.error("Something went wrong! isSoftphonePanelVisible Errors:", responsee.errors);
        }
      },
    });
  }

  static getCallHistory() {
    // @ts-ignore
    sforce.opencti.runApex({
      apexClass: 'rocketphone.RPGetLoggedInUserInfo',
      methodName: 'getLoggedInUserCallLogs',
      methodParams: '',
      callback: (response: any) => {
        let callRecords = [];

        if (response.success) {
          callRecords = JSON.parse(response.returnValue.runApex).map((result: any) => JSON.parse(result))
        } else {
          console.error('Error refreshing token:');
        }

        store.dispatch(getCallHistoryInitState({ listData: callRecords, loading: false }));
      }
    })
  };

  static getCallRecordDetailsByCallRecordId(callRecordId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // @ts-ignore
      sforce.opencti.runApex({
        apexClass: 'rocketphone.RPGetLoggedInUserInfo',
        methodName: 'getCallLogsUsingCallId',
        methodParams: `callId=${callRecordId}`,
        callback: (response: any) => {
          let objects = [];
          if (response.success) {
            objects = JSON.parse(response.returnValue.runApex).map((result: any) => JSON.parse(result));
            resolve(objects?.[0] || {});
          } else {
            console.error('Error on featching call object by callRecordId');
            reject(new Error('Error on featching call object by callRecordId'));
          }
        }
      });
    });
  }

  static getScheduledCalls() {
    // @ts-ignore
    sforce.opencti.runApex({
      apexClass: 'rocketphone.RPGetLoggedInUserInfo',
      methodName: 'getLoggedInUserTasks',
      methodParams: '',
      callback: (response: any) => {
        let scheduledCalls = [];

        if (response.success) {
          scheduledCalls = JSON.parse(response.returnValue.runApex).map((result: any) => JSON.parse(result))
        } else {
          console.error('Error refreshing token:');
        }

        store.dispatch(getScheduledCallsInitState({ listData: scheduledCalls, loading: false }));
      }
    })
  };

  static getContactSearchByPhone(phoneNumber: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // @ts-ignore
      sforce.opencti.runApex({
        apexClass: 'rocketphone.RPGetLoggedInUserInfo',
        methodName: 'getContactsByPhone',
        methodParams: `phoneNumber=${phoneNumber}`,
        callback: (response: any) => {
          let objects = [];
          if (response.success) {
            objects = JSON.parse(response.returnValue.runApex).map((result: any) => JSON.parse(result));
            resolve(objects);
          } else {
            console.error('Error on featching object by phone');
            reject(new Error('Error on featching object by phone'));
          }
        }
      });
    });
  }

  static getObjectDetails(type: string, objectId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // @ts-ignore
      sforce.opencti.runApex({
        apexClass: 'rocketphone.RPGetLoggedInUserInfo',
        methodName: 'getObjectsById',
        methodParams: `remoteId=${objectId}&typeOfObject=${type}`,
        callback: (response: any) => {
          if (response.success) {
            const objectDetails = JSON.parse(response.returnValue.runApex);
            resolve(objectDetails);
          } else {
            console.error('Error refreshing token:');
            reject(new Error('Error on featching object'));
          }
        }
      });
    });
  }

  static processLWCMessage(message: any){
    // GET_BOOKMARKS // CALL_ID
    // GET_TRANSCRIPT // CALL_ID
    // GET_INPROGRESS_CALL_DETAILS
    // REGISTER
    // UNREGISTER
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
    const callAgent = store.getState()?.auth?.user;
    
    // This is connected object
    const callingContact = store.getState()?.callingContact?.data;
    console.log("Main Conmponent", "callingContact : ", {callingContact});
    if (message?.type === 'GET_INPROGRESS_CALL_DETAILS') {
      console.log("Main Conmponent", "Published when received GET_INPROGRESS_CALL_DETAILS msg, msgObjcallDetails: ", {callDetails});
      SaleforceService.publishObject({
        type: 'GET_INPROGRESS_CALL_DETAILS', 
        callDetails: callDetails,
        assignedUser: callAgent
      }, "rocketphone__RPISTOLWC__c");
      console.log(message?.type);
    } else if (message?.type === 'GET_INPROGRESS_CALL_BOOKMARKS') {
      let callRecordId = message?.callId;
      console.log("Main Conmponent", "callRecordId : ", {callRecordId});
      let captures = store.getState()?.callTranscript?.capturesTranscriptMap;
      console.log("Main Conmponent", "Published when received GET_INPROGRESS_CALL_BOOKMARKS msg, msgObjcallDetails: ", {captures});
      let InProgressCallCaptures = captures[callRecordId];
      console.log("Main Conmponent", "InProgressCallCaptures : ", {InProgressCallCaptures});
      SaleforceService.publishObject({
        type: 'GET_INPROGRESS_CALL_BOOKMARKS', 
        callDetails: callDetails,
        bookmarkCaptures: InProgressCallCaptures
      }, "rocketphone__RPISTOLWC__c");
    }
    else if (message?.type === 'GET_INPROGRESS_CALL_SFCONTACT') {
      console.log("Salesforce Service", "GET_INPROGRESS_CALL_SFCONTACT received msg : ", {message});
      SaleforceService.publishObject({
        type: 'GET_INPROGRESS_CALL_SFCONTACT', 
        callDetails: callDetails,
        sfcontact: callingContact
      }, "rocketphone__RPISTOLWC__c");
    }
  }
}